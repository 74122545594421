import React, { useEffect } from "react";
import { useUserStore } from "../../store/user.store";
import {
  BookingFlowEntryType,
  BookingFlowType,
} from "../../../models/constants/BookingFlowType";
import { Step, useStep } from "../../hooks/useStep";

export function VoucherPreselectPage() {
  const { setBookingFlowType, setBookingFlowEntryType } = useUserStore();
  const { goToStep } = useStep();

  useEffect(() => {
    setBookingFlowEntryType(BookingFlowEntryType.Default);
    setBookingFlowType(BookingFlowType.VoucherPurchase);
    goToStep(Step.Service);
  }, []);

  return null;
}
